const fragmentShader = `
  uniform sampler2D uTexture;
  uniform vec2 uResolution;
  uniform float uTime;
  uniform vec2 uMouse;

  varying vec2 vUv;

  void main() {
    vec2 uv = vUv;
    vec2 mouse = uMouse; // Already in NDC space, no need to divide

    vec2 dir = uv - mouse;

    float dist = length(dir);

    float amplitude = 0.04;
    float baseFrequency = 3.0;
    float speed = 1.0;

    // Modulate frequency and speed based on mouse
    float frequency = baseFrequency * (1.0 + mouse.x * 0.5);
    speed += mouse.y * 0.5;

    float displacement = amplitude * sin(uv.y * frequency + uTime * speed);

    vec2 displacedUV = uv + displacement * normalize(dir);
    vec4 color = texture2D(uTexture, displacedUV);

    gl_FragColor = color;
  }
`;

export default fragmentShader;
