// extracted by mini-css-extract-plugin
export var activeOption = "styles-module--activeOption--309e3";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--a383b";
export var gridItem = "styles-module--gridItem--5e0ee";
export var gridItemContent = "styles-module--gridItemContent--cb298";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--7dbf2";
export var uppercase = "styles-module--uppercase--65f98";