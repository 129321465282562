import React from "react";
import * as styles from "./styles.module.scss";
import { IPaletteColor } from "~schemas";
import { TPalette } from "../..";
import cn from "classnames";
import { hexToRGB } from "~utils";
import { CopyableText } from "~components";

interface IProps {
  color: IPaletteColor;
  activePalette: TPalette;
  isAccentColor?: boolean;
}

const PaletteColorTile = ({ color, activePalette, isAccentColor }: IProps) => {
  const SLICE_BACKGROUND_COLOR_HEX = "#EFEFEF";

  if (!color.color.hex) return null;

  const rgb = hexToRGB(color.color.hex);
  const { blue, green, red } = rgb;

  const getTextColor = () => {
    // Calculate the brightness using the formula: (R * 299 + G * 587 + B * 114) / 1000
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    return brightness > 128 ? "black" : "white";
  };
  const textColor = getTextColor();

  const getFlex: () => number = () => {
    switch (activePalette) {
      case "core":
        return 1;
      case "light":
        return color.lightPaletteRatio;
      case "dark":
        return color.darkPaletteRatio;
    }
  };
  const flex = getFlex();

  const borderColor =
    color.color.hex === SLICE_BACKGROUND_COLOR_HEX
      ? "#DEDEDE"
      : color.color.hex;

  return (
    <div
      style={{
        background: color.color.hex,
        flex,
        color: textColor,
        border: `1px solid ${borderColor}`
      }}
      className={cn("b2", styles.container, {
        [styles.accentColor]: isAccentColor
      })}
    >
      <p className={styles.title}>{color.color.title}</p>
      <div className={styles.colorValues}>
        <CopyableText text={color.color.hex?.toUpperCase() || ""} />
        <CopyableText text={`R ${red} G ${green} B ${blue}`} />
      </div>
    </div>
  );
};

export default PaletteColorTile;
