import React from "react";
import {
  HTMLVideo,
  SectionConfig,
  SliceConfig,
  Grid,
  IntersectionAnimation
} from "~components";
import * as styles from "./styles.module.scss";

const SimpleVideo = ({ data: { videoURL, sliceConfig } }) => {
  if (!videoURL) {
    return null;
  }

  return (
    <SectionConfig noPadding config={sliceConfig}>
      <IntersectionAnimation className={styles.intersectionAnimation}>
        <SliceConfig config={sliceConfig}>
          <HTMLVideo
            className={styles.video}
            src={videoURL}
            autoPlay
            muted
            loop
            playsInline
          />
        </SliceConfig>
      </IntersectionAnimation>
    </SectionConfig>
  );
};

export default SimpleVideo;
