import React from "react";
import cn from "classnames";
import {
  Grid,
  SectionConfig,
  SliceConfig,
  PortableTextRenderer,
  IntersectionAnimation
} from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    heading: string;
    _rawText: any;
    _rawSubheading: any;
    sliceConfig: ISliceConfig;
  };
}

const FeaturedContentBlock = ({
  data: { heading, _rawSubheading, _rawText, sliceConfig }
}: IProps) => {
  return (
    <SectionConfig className={styles.section}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.content}>
          <IntersectionAnimation>
            <h3 className={cn(["label", styles.heading])}>{heading}</h3>
          </IntersectionAnimation>
          <IntersectionAnimation>
            <PortableTextRenderer
              className={styles.subheading}
              rawText={_rawSubheading}
            />
          </IntersectionAnimation>
          <IntersectionAnimation>
            <PortableTextRenderer className={styles.text} rawText={_rawText} />
          </IntersectionAnimation>
        </div>
      </SliceConfig>
    </SectionConfig>
  );
};

export default FeaturedContentBlock;
