// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--4d9db";
export var heading = "styles-module--heading--8c1f8";
export var headingContainer = "styles-module--headingContainer--098bd";
export var imageContainer = "styles-module--imageContainer--d9702";
export var imageGrid = "styles-module--imageGrid--eebfe";
export var images = "styles-module--images--f3a0c";
export var intersectionAnimation = "styles-module--intersectionAnimation--cd54e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";