// extracted by mini-css-extract-plugin
export var container = "styles-module--container--a92c7";
export var desktop = "1340px";
export var giant = "2200px";
export var gtAmerica = "styles-module--gtAmerica--c92a9";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var reformaRegular = "styles-module--reformaRegular--f8628";
export var riformaLight = "styles-module--riformaLight--a7995";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--8bfa2";