import React from "react";
import { SliceConfig } from "~components";
import { IFontGallery } from "~schemas";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  data: IFontGallery;
}

const FontGallery = ({ data: { sliceConfig } }: IProps) => {
  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <div className={cn(styles.text, styles.riformaLight)}>
          Riforma Light
        </div>
        <div className={cn(styles.text, styles.reformaRegular)}>
          Riforma Regular
        </div>
        <div className={cn(styles.text, styles.gtAmerica)}>GT America Mono</div>
      </SliceConfig>
    </section>
  );
};

export default FontGallery;
