import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
}

const Grid = ({ children, className, noPadding = false }: IProps) => (
  <div
    className={cn(
      styles.grid,
      { [styles.noPadding]: noPadding === true },
      className
    )}
  >
    {children}
  </div>
);
export default Grid;
