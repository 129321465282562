import React from "react";
import cn from "classnames";
import {
  SliceConfig,
  SectionConfig,
  Grid,
  IntersectionAnimation
} from "~components";
import { ISimpleImage, ISliceConfig } from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.scss";

interface IProps {
  data: ISimpleImage;
}

const SimpleImage = ({
  data: { heading, image, fullWidthImage, collapse, roundedCorners, textColor }
}: IProps) => {
  if (!image) {
    return null;
  }

  const sliceConfig: ISliceConfig = {
    slicePadding: {
      paddingBottom:
        collapse === "bottom" || collapse === "both" ? "none" : "regular",
      paddingTop: collapse === "top" || collapse === "both" ? "none" : "regular"
    }
  };

  return (
    <SectionConfig noPadding>
      <SliceConfig config={sliceConfig}>
        {heading && (
          <IntersectionAnimation className={styles.headingContainer}>
            <h3 className={cn(["b1", styles.heading])}>{heading}</h3>
          </IntersectionAnimation>
        )}

        <IntersectionAnimation animation="fadeGrow">
          <Grid className={cn("hey", styles.grid)} noPadding>
            <div
              className={cn([
                styles.imageWrapper,
                {
                  [styles.fullWidth]: fullWidthImage,
                  [styles.rounded]: roundedCorners
                }
              ])}
            >
              <GatsbyImage
                style={{
                  width: `100%`
                }}
                alt=""
                image={image.asset.gatsbyImageData}
                objectFit="contain"
              />
            </div>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </SectionConfig>
  );
};

export default SimpleImage;
