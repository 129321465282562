// extracted by mini-css-extract-plugin
export var colorBackground = "styles-module--colorBackground--9b0d6";
export var container = "styles-module--container--dc519";
export var content = "styles-module--content--29c06";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var principle = "styles-module--principle--ca6a9";
export var principles = "styles-module--principles--e7750";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--48125";
export var visible = "styles-module--visible--3a93b";