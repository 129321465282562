import React from "react";
import cn from "classnames";
import {
  DisplayTag,
  IntersectionAnimation,
  PortableTextRenderer
} from "~components";
import * as styles from "./styles.module.scss";
import { IColor } from "~schemas";

interface IProps {
  data: {
    title: string;
    _rawBody: any;
    _rawDescription: any;
    tags: {
      title: string;
      backgroundColor: IColor;
      textColor: IColor;
    }[];
  };
  index: number;
  inView: boolean;
}

const CardGridItem = ({
  data: { title, _rawBody, tags, _rawDescription },
  index,
  inView
}: IProps) => {
  return (
    <IntersectionAnimation
      className={cn([styles.container])}
      trigger={inView}
      animation="fadeGrow"
      delay={index * 50}
    >
      <li className={styles.gridItem}>
        <div className={styles.leftColumn}>
          <div>
            <p className={cn("label", styles.title)}>{title}</p>
            <PortableTextRenderer rawText={_rawBody} />
          </div>

          <div>
            {tags?.[0] && (
              <div className={styles.tags}>
                {tags.map(({ title, backgroundColor, textColor }, i) => {
                  return (
                    <DisplayTag
                      key={i}
                      title={title}
                      backgroundColor={backgroundColor}
                      textColor={textColor}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {_rawDescription && (
          <PortableTextRenderer
            rawText={_rawDescription}
            className={styles.description}
          />
        )}
      </li>
    </IntersectionAnimation>
  );
};

export default CardGridItem;
