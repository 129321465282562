import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { CopyableText } from "~components";

interface IProps {
  title: string;
  hex: string;
  textColor: string;
  borderColor: string;
  rgb: {
    red: number;
    green: number;
    blue: number;
  };
}

const DesktopColorTile = ({
  borderColor,
  hex,
  textColor,
  title,
  rgb
}: IProps) => {
  const { blue, green, red } = rgb;
  return (
    <div className={styles.wrapper}>
      <div
        style={{
          background: hex,
          color: textColor,
          border: `1px solid ${borderColor}`
        }}
        className={cn("b2", styles.container)}
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.colorValues}>
          <CopyableText text={hex.toUpperCase()} />
          <CopyableText text={`R ${red} G ${green} B ${blue}`} />
        </div>
      </div>
    </div>
  );
};

export default DesktopColorTile;
