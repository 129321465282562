// extracted by mini-css-extract-plugin
export var colOne = "styles-module--colOne--239bd";
export var colTwo = "styles-module--colTwo--9a29b";
export var container = "styles-module--container--0cb69";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--10e5c";
export var propertyValues = "styles-module--propertyValues--8d873";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";