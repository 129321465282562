import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  text: string;
  onClick: () => void;
  isActive: boolean;
}

const PaletteButton = ({ onClick, text, isActive }: IProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.button, { [styles.active]: isActive })}
    >
      <span className={cn("label")}>{text}</span>
    </button>
  );
};

export default PaletteButton;
