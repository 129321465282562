import React from "react";
import cn from "classnames";
import {
  SliceConfig,
  PortableTextRenderer,
  IntersectionAnimation
} from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    heading: string;
    _rawText: any;
    sliceConfig: ISliceConfig;
  };
}

const HeadingWithText = ({
  data: { heading, _rawText, sliceConfig }
}: IProps) => {
  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <div className={styles.content}>
          {heading && (
            <IntersectionAnimation>
              <h3 className={cn(["label", styles.heading])}>{heading}</h3>
            </IntersectionAnimation>
          )}

          <IntersectionAnimation>
            <PortableTextRenderer rawText={_rawText} />
          </IntersectionAnimation>
        </div>
      </SliceConfig>
    </section>
  );
};

export default HeadingWithText;
