import React from "react";
import * as styles from "./styles.module.scss";
import SVG from "../SVG";
import cn from "classnames";
import { useApp } from "~hooks";

interface IProps {
  text: string;
  className?: string;
}

const CopyableText = ({ text, className }: IProps) => {
  const { updateNotification } = useApp();

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(text);
    updateNotification("Copied to clipboard!");
  };

  return (
    <div className={cn(styles.container, className)}>
      <button onClick={copyTextToClipboard} className={styles.button}>
        <SVG svg="copy" className={styles.copyIcon} />
      </button>

      <p>{text}</p>
    </div>
  );
};

export default CopyableText;
