// extracted by mini-css-extract-plugin
export var container = "styles-module--container--56fa5";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--e518a";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--d7fcf";
export var paddingTopRegular = "styles-module--paddingTopRegular--f270e";
export var paddingTopSmall = "styles-module--paddingTopSmall--d30cf";
export var paddingXRegular = "styles-module--paddingXRegular--bf571";
export var paddingXSmall = "styles-module--paddingXSmall--d0079";
export var roundedBorders = "styles-module--roundedBorders--4ef30";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";