// extracted by mini-css-extract-plugin
export var container = "styles-module--container--2ed39";
export var description = "styles-module--description--06df8";
export var desktop = "1340px";
export var giant = "2200px";
export var gridItem = "styles-module--gridItem--9fbc8";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--4f2ae";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tag = "styles-module--tag--dfd6b";
export var tags = "styles-module--tags--8be8a";
export var title = "styles-module--title--27214";