// extracted by mini-css-extract-plugin
export var container = "styles-module--container--db27b";
export var content = "styles-module--content--fcb01";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--7fc06";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--ed899";
export var wordmarkContainer = "styles-module--wordmarkContainer--43102";