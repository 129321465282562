// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var fullWidth = "styles-module--fullWidth--aea29";
export var giant = "2200px";
export var grid = "styles-module--grid--94c0b";
export var heading = "styles-module--heading--b4612";
export var headingContainer = "styles-module--headingContainer--00b61";
export var imageWrapper = "styles-module--imageWrapper--ea269";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var rounded = "styles-module--rounded--dcbbf";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";