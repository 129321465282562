import React from "react";
import cn from "classnames";
import { SliceConfig, IntersectionAnimation, CardGridItem } from "~components";
import { useInView } from "react-intersection-observer";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    columns: number;
    cards: Array<object>;
    sliceConfig: ISliceConfig;
  };
}

const CardGrid = ({ data: { columns, cards, sliceConfig } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <ul
            className={cn([styles.grid], {
              [styles.twoCol]: columns === 2
            })}
            ref={ref}
          >
            {cards.map((card: any, i) => (
              <CardGridItem key={i} index={i} data={card} inView={inView} />
            ))}
          </ul>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default CardGrid;
