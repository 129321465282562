// extracted by mini-css-extract-plugin
export var activePage = "styles-module--activePage--bc5f7";
export var container = "styles-module--container--d5c84";
export var desktop = "1340px";
export var disabledLink = "styles-module--disabledLink--a5b19";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var listItem = "styles-module--listItem--81ca0";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";