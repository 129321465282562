// Need to update 'cateogry' options in 'colorGrid.ts' to match these
type TColorCategory = `mono` | `digital/ux` | `brand` | `secondary`;

export interface IColorData {
  id: string;
  title: string;
  category: TColorCategory;
  hex: string;
  display: `light` | `dark`;
}

const colors: IColorData[] = [
  // Mono
  {
    id: "black",
    title: "Black",
    category: "mono",
    hex: "#000000",
    display: "dark"
  },
  {
    id: "white",
    title: "White",
    category: "mono",
    hex: "#FFFFFF",
    display: "light"
  },
  {
    id: "black-90",
    title: "Black 90",
    category: "mono",
    hex: "#1e1e1e",
    display: "dark"
  },
  {
    id: "black-80",
    title: "Black 80",
    category: "mono",
    hex: "#383838",
    display: "dark"
  },
  {
    id: "black-70",
    title: "Black 70",
    category: "mono",
    hex: "#4a4a4a",
    display: "dark"
  },
  {
    id: "black-60",
    title: "Black 60",
    category: "mono",
    hex: "#595959",
    display: "dark"
  },
  {
    id: "black-50",
    title: "Black 50",
    category: "mono",
    hex: "#787878",
    display: "light"
  },
  {
    id: "black-40",
    title: "Black 40",
    category: "mono",
    hex: "#a3a3a3",
    display: "light"
  },
  {
    id: "black-30",
    title: "Black 30",
    category: "mono",
    hex: "#c7c7c7",
    display: "light"
  },
  {
    id: "black-20",
    title: "Black 20",
    category: "mono",
    hex: "#dddee2",
    display: "light"
  },

  {
    id: "black-10",
    title: "Black 10",
    category: "mono",
    hex: "#F0F0F0",
    display: "light"
  },

  // Digital/UX
  {
    id: "ux-error",
    title: "UX Error",
    category: "digital/ux",
    hex: "#f75757",
    display: `dark`
  },
  {
    id: "ux-success",
    title: "UX Success",
    category: "digital/ux",
    hex: "#07cb9c",
    display: `dark`
  },
  // Brand
  {
    id: "off-black",
    title: "Off Black",
    category: "brand",
    hex: "#434343",
    display: "dark"
  },
  {
    id: "off-white",
    title: "Off White",
    category: "brand",
    hex: "#EFEFEF",
    display: "light"
  },
  {
    id: "brand-black",
    title: "Black",
    category: "brand",
    hex: "#000000",
    display: "dark"
  },
  {
    id: "lilac",
    title: "Lilac",
    category: "brand",
    hex: "#B277FD",
    display: "dark"
  },
  // Secondary
  {
    id: "lilac-dark-2",
    title: "Lilac Dark 2",
    category: "secondary",
    hex: "#483166",
    display: "dark"
  },
  {
    id: "chartreuse-dark-2",
    title: "Chartreuse Dark 2",
    category: "secondary",
    hex: "#8F9D08",
    display: "dark"
  },
  {
    id: "lilac-dark-1",
    title: "Lilac Dark 1",
    category: "secondary",
    hex: "#8438E8",
    display: "dark"
  },
  {
    id: "chartreuse-dark-1",
    title: "Chartreuse Dark 1",
    category: "secondary",
    hex: "#B3C03A",
    display: "light"
  },
  {
    id: "lilac-light-3",
    title: "Lilac Light 3",
    category: "secondary",
    hex: "#CFAAFF",
    display: "light"
  },
  {
    id: "chartreuse",
    title: "Chartreuse",
    category: "secondary",
    hex: "#CDD95E",
    display: "light"
  },
  {
    id: "lilac-light-2",
    title: "Lilac Light 2",
    category: "secondary",
    hex: "#DEC5FF",
    display: "light"
  },
  {
    id: "chartreuse-light-2",
    title: "Chartreuse Light 2",
    category: "secondary",
    hex: "#F3FF8C",
    display: "light"
  },
  {
    id: "lilac-light-1",
    title: "Lilac Light 1",
    category: "secondary",
    hex: "#E8D7FF",
    display: "light"
  },
  {
    id: "chartreuse-light-1",
    title: "Chartreuse Light 1",
    category: "secondary",
    hex: "#FCFFDB",
    display: "light"
  }
];

export default colors;
