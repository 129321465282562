import React from "react";
import cn from "classnames";
import { Grid } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
}

const SectionConfig = ({ children, className, noPadding }: IProps) => {
  return (
    <section className={cn(styles.container, className)}>
      <Grid noPadding={noPadding}>{children}</Grid>
    </section>
  );
};
export default SectionConfig;
