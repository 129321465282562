// extracted by mini-css-extract-plugin
export var container = "styles-module--container--e139e";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--046d3";
export var gridItem = "styles-module--gridItem--b3286";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var twoCol = "styles-module--twoCol--9d1a6";