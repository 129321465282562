import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { SVG } from "~components";
import { useAccordion } from "~hooks";

interface IProps {
  title: string;
  hex: string;
  textColor: string;
  borderColor: string;
  rgb: {
    red: number;
    green: number;
    blue: number;
  };
}

const MobileColorTile = ({
  borderColor,
  hex,
  textColor,
  title,
  rgb
}: IProps) => {
  const { blue, green, red } = rgb;
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  return (
    <div
      className={cn("b2", styles.container, { [styles.expanded]: isExpanded })}
      style={{
        background: hex,
        color: textColor,
        border: `1px solid ${borderColor}`
      }}
    >
      <button
        className={styles.button}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {title}
        <SVG className={styles.chevron} svg="chevronDown" />
      </button>
      <div className={styles.accordion} style={{ height: containerHeight }}>
        <div className={styles.accordionContent} ref={contentRef}>
          <p className={styles.hex}>{hex}</p>
          <p>{`R ${red} G ${green} B ${blue}`}</p>
        </div>
      </div>
    </div>
  );
};

export default MobileColorTile;
