// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--2fa9c";
export var appearDown = "styles-module--appear-down--c0943";
export var appearLeft = "styles-module--appear-left--5c00d";
export var appearRight = "styles-module--appear-right--0984d";
export var appearUp = "styles-module--appear-up--d5f29";
export var background = "styles-module--background--0fee6";
export var categoryGroup = "styles-module--categoryGroup--6f206";
export var container = "styles-module--container--a5403";
export var desktop = "1340px";
export var giant = "2200px";
export var hamburger = "styles-module--hamburger--ba835";
export var header = "styles-module--header--6fe10";
export var headerOpen = "styles-module--headerOpen--f3eae";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var line = "styles-module--line--d883c";
export var logo = "styles-module--logo--66b0a";
export var mobile = "400px";
export var nav = "styles-module--nav--566f7";
export var navContainer = "styles-module--navContainer--0a41d";
export var navItems = "styles-module--navItems--9d467";
export var open = "styles-module--open--7defd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--2745d";