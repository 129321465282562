import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import { IPalettePreview } from "~schemas";
import { SliceConfig } from "~components";
import PaletteButton from "./components/PaletteButton";
import PaletteColorTile from "./components/PaletteColorTile";

interface IProps {
  data: IPalettePreview;
}

export type TPalette = "core" | "light" | "dark";

const PalettePreview = ({
  data: { sliceConfig, accentPalette, corePalette }
}: IProps) => {
  const [activePalette, setActivePalette] = useState<TPalette>("core");

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.buttons}>
        <PaletteButton
          text="Core Palette"
          isActive={activePalette === "core"}
          onClick={() => setActivePalette("core")}
        />
        <PaletteButton
          text="Light Brand Palette"
          isActive={activePalette === "light"}
          onClick={() => setActivePalette("light")}
        />
        <PaletteButton
          text="Dark Brand Palette"
          isActive={activePalette === "dark"}
          onClick={() => setActivePalette("dark")}
        />
      </div>

      <div className={styles.colors}>
        <div className={styles.colorRow}>
          {corePalette?.map((color, i) => (
            <PaletteColorTile
              activePalette={activePalette}
              color={color}
              key={i}
            />
          ))}
        </div>
        <div className={styles.colorRow}>
          {accentPalette?.map((color, i) => (
            <PaletteColorTile
              activePalette={activePalette}
              color={color}
              isAccentColor
              key={i}
            />
          ))}
        </div>
      </div>
    </SliceConfig>
  );
};

export default PalettePreview;
