import React, { useEffect } from "react";
import cn from "classnames";
import { useApp, usePageNavigation, useAccordion } from "~hooks";
import { SVG, PageCategoryGroup } from "~components";
import { Link } from "gatsby";
import { TCategory } from "src/hooks/usePageNavigation";

import * as styles from "./styles.module.scss";

const Header = () => {
  // --------------------------------------------------------------------------
  // imports / hooks

  const { isHeaderOpen, setIsHeaderOpen } = useApp();
  const { pageCategories } = usePageNavigation();

  const { containerHeight, contentRef, setIsExpanded } = useAccordion();

  useEffect(() => {
    setIsExpanded(isHeaderOpen);
  }, [isHeaderOpen]);

  return (
    <>
      <div
        aria-hidden
        className={cn(styles.background, { [styles.headerOpen]: isHeaderOpen })}
        onClick={() => setIsHeaderOpen(false)}
      />

      <div className={cn(styles.container)}>
        <header className={cn(styles.header)}>
          <Link to="/" className={styles.logo}>
            <SVG svg="wordmark" className={styles.wordmark} />
          </Link>

          <button
            onClick={() => setIsHeaderOpen((prev) => !prev)}
            type="button"
            className={cn(styles.hamburger, { [styles.open]: isHeaderOpen })}
          >
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </button>
        </header>

        <div
          style={{ height: containerHeight }}
          className={cn(styles.navContainer)}
        >
          <nav ref={contentRef} className={styles.nav}>
            <div className={styles.navItems}>
              {pageCategories?.map((category: TCategory) => (
                <div key={category.id} className={styles.categoryGroup}>
                  <PageCategoryGroup
                    pages={category.pages}
                    title={category.title}
                  />
                </div>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
