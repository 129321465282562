import React from "react";
import cn from "classnames";
import { Grid, SliceConfig, IntersectionAnimation } from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    font: string;
    sliceConfig: ISliceConfig;
  };
}

const TypeExampleTwoCol = ({ data: { font, sliceConfig } }: IProps) => {
  const getFontProperties = (font: string) => {
    let fontProperties;

    switch (font) {
      case "riforma-ll-light":
        fontProperties = {
          label: "LL Riforma",
          family: "Light",
          lineHeight: "130%",
          letterSpacing: "0.5%",
          content: "Make Work Flow",
          fontStyles: {
            fontFamily: "Riforma LL Light",
            fontSize: "16px",
            lineHeight: "130%",
            letterSpacing: "0.5%"
          }
        };
        break;

      case "riforma-ll-regular":
        fontProperties = {
          label: "LL Riforma",
          family: "Regular",
          lineHeight: "130%",
          letterSpacing: "0.5%",
          content: "AMR-enabled processes empower",
          fontStyles: {
            fontFamily: "Riforma LL Regular",
            fontSize: "16px",
            lineHeight: "130%",
            letterSpacing: "0.5%"
          }
        };
        break;

      case "gt-america-mono-regular":
        fontProperties = {
          label: "LL Riforma",
          family: "Regular",
          lineHeight: "130%",
          letterSpacing: "0.5%",
          content: "LIFT.MOVE.REPEAT",
          fontStyles: {
            fontFamily: "GT America Mono",
            fontSize: "16px",
            lineHeight: "130%",
            letterSpacing: "0.5%"
          }
        };
      default:
        break;
    }

    return fontProperties;
  };

  const fontProperties = getFontProperties(font);

  if (!fontProperties) return null;

  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <Grid>
            <div className={styles.properties}>
              <p className="b1">{fontProperties.label}</p>

              <div className={cn(["b2", styles.propertyValues])}>
                <p>{fontProperties.family}</p>
                <p>{fontProperties.fontStyles.fontSize}</p>
                <p>{fontProperties.lineHeight}</p>
                <p>{fontProperties.letterSpacing}</p>
              </div>
            </div>

            <p style={fontProperties.fontStyles} className={styles.colOne}>
              AMR robots, which stands for Autonomous Mobile Robots, are special
              robots that can move around and do tasks without needing someone
              to control them directly. They are designed to help people in
              different places like factories, hospitals, and warehouses. The
              purpose of these robots is to make our lives easier by doing jobs
              that can be repetitive, tiring, or even dangerous for humans. One
              of the biggest benefits of AMR robots is that they can save a lot
              of time and effort. They can move quickly and efficiently, which
              means they can complete tasks faster than humans. For example, in
              a warehouse, instead of workers having to walk back and forth to
              pick up items, an AMR robot can do it much faster. This saves time
              and allows people to focus on more important things. Another
              advantage of AMR robots is that they can help reduce the risk of
              accidents or injuries. Some tasks, like carrying heavy objects or
              working in hazardous environments, can be dangerous for humans. By
              using AMR robots, we can avoid putting people in harm's way. The
              robots are designed to handle these tasks safely, without the need
              for human intervention.
            </p>

            <p style={fontProperties.fontStyles} className={styles.colTwo}>
              AMR robots can also improve productivity. Since they can work
              nonstop without getting tired, they can keep going for long
              periods of time. This means that the work gets done faster and
              more efficiently. For example, in a hospital, AMR robots can
              deliver medicine or supplies to different rooms without needing
              nurses or doctors to do it, allowing the healthcare professionals
              to focus on patient care. Lastly, AMR robots can also be
              programmed to learn and adapt. They can use sensors and cameras to
              navigate their surroundings and make decisions on their own. This
              makes them really smart and able to handle different situations.
              They can even communicate with each other to coordinate tasks and
              work together as a team. In summary, AMR robots are special robots
              that move around by themselves and do tasks to help people. They
              save time, make work safer, improve productivity, and can learn on
              their own. They are like helpful assistants hat make our lives
              easier and more efficient.
            </p>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default TypeExampleTwoCol;
