import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { IColor } from "~schemas";

interface IDisplayTag {
  title: string;
  backgroundColor: IColor;
  textColor: IColor;
}

const DisplayTag = ({ title, backgroundColor, textColor }: IDisplayTag) => {
  const colorStyles = {
    backgroundColor: backgroundColor?.hex || "var(--color-lilac)",
    color: textColor?.hex || `var(--color-white)`
  };

  return (
    <p style={colorStyles} className={cn("label", styles.container)}>
      {title}
    </p>
  );
};

export default DisplayTag;
