import React from "react";
import cn from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { Grid, SVG } from "~components";

import * as styles from "./styles.module.scss";

const query = graphql`
  query FooterQuery {
    sanitySettings {
      footer {
        showFooter
        _rawFooterText
        clientName
      }
    }
  }
`;

interface IProps {
  theme?: string;
}

const Footer = ({ theme }: IProps) => {
  const {
    sanitySettings: { footer }
  } = useStaticQuery(query);

  const { showFooter } = footer;

  const colorStyles = {
    backgroundColor: theme === "dark" ? `#000000` : `#EFEFEF`,
    color: theme === "dark" ? `var(--color-black-90)` : `var(--color-white)`
  };

  const isLight = theme?.toLowerCase() === "light";

  if (!showFooter) return null;

  return (
    <section style={colorStyles} className={styles.container}>
      <Grid>
        {/* <figure className={styles.wordmarkContainer}>
          <SVG svg="wordmark" />
        </figure> */}

        <div className={cn([styles.copyright, { [styles.light]: isLight }])}>
          <p className="label">© 2024 OTTO MOTORS BY ROCKWELL AUTOMATION</p>
          <p className="label">Made with Love + Money</p>
        </div>
      </Grid>
    </section>
  );
};

export default Footer;
