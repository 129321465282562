import React, { Suspense, useRef } from "react";
import { IntersectionAnimation, NoiseSpotlightMesh } from "~components";
import { ISliceConfig } from "~schemas";
import { Canvas } from "@react-three/fiber";
import { useWindowDimensions } from "~hooks";
import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

interface IProps {
  data: {
    title: string;
    backgroundImage: {
      asset: {
        gatsbyImageData: any;
      };
    };
    sliceConfig: ISliceConfig;
  };
}

const Hero = ({ data: { title, backgroundImage } }: IProps) => {
  const { windowSize } = useWindowDimensions();
  const canvasRef = useRef();

  return (
    <section className={styles.container}>
      {backgroundImage && (
        <GatsbyImage
          style={{ position: "absolute", inset: 0 }}
          image={backgroundImage.asset.gatsbyImageData}
          alt=""
        />
      )}

      {!backgroundImage && (
        <div className={styles.canvas}>
          <Canvas
            orthographic
            shadows
            camera={{ zoom: 1, position: [0, 0, 1] }}
            style={{ background: `#000419` }}
          >
            <ambientLight intensity={0.5} />

            <pointLight
              position={[0, 10, 30]}
              rotation={[0, 90, 0]}
              intensity={1}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              castShadow
            />

            <Suspense>
              <NoiseSpotlightMesh
                canvas={canvasRef.current}
                dimensions={windowSize}
              />
            </Suspense>
          </Canvas>
        </div>
      )}

      <div className={styles.text}>
        <IntersectionAnimation>
          <h1 className="d1">{title}</h1>
        </IntersectionAnimation>
      </div>
    </section>
  );
};

export default Hero;
