import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

import fragmentShader from "./glsl/fragment";
import vertexShader from "./glsl/vertex";

const NoiseSpotlightMesh = ({ dimensions }) => {
  const groupRef = useRef();

  const textureLoader = new THREE.TextureLoader();
  const texture = textureLoader.load(`/images/sample.jpg`);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;

  const { width, height } = dimensions;
  const aspectRatio = 16 / 9;

  const geometryWidth = 1600;
  const geometryHeight = 1600 / aspectRatio;

  const uniforms = {
    uTexture: { value: texture },
    uResolution: {
      value: new THREE.Vector2(dimensions.width, dimensions.height)
    },
    uMouse: { value: new THREE.Vector2(0, 0) }, // Initialize the uMouse uniform
    uTime: { value: 0 } // Initialize the uTime uniform
  };

  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader,
    fragmentShader
  });

  // useFrame(() => {
  //   console.log(uniforms.uMouse.value, `uniforms`);
  // });

  useEffect(() => {
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Update the uTime uniform value
      uniforms.uTime.value += 0.01;
    };

    animate();

    // Add event listener
    const onMouseMove = (event) => {
      // Transform the mouse position from [0, dimensions.width] and [0, dimensions.height]
      // to [-1, 1] for both axes
      uniforms.uMouse.value.x = event.clientX / dimensions.width;
      // console.log(`uniforms.uMouse.value.x: `, uniforms.uMouse.value.x);
      uniforms.uMouse.value.y = -(event.clientY / dimensions.height);
    };

    window.addEventListener(`mousemove`, onMouseMove);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener(`mousemove`, onMouseMove);
    };
  }, [dimensions.width, dimensions.height]);

  return (
    <group ref={groupRef}>
      <mesh frustumCulled={false} material={material}>
        <planeBufferGeometry args={[geometryWidth, geometryHeight]} />
      </mesh>
    </group>
  );
};

export default NoiseSpotlightMesh;
