import React from "react";
import { IColor } from "~schemas";
import { hexToRGB } from "~utils";
import { useBreakpoints } from "~hooks";
import DesktopColorTile from "./Desktop";
import MobileColorTile from "./Mobile";

interface IProps {
  color: IColor;
}

const ColorTile = ({ color }: IProps) => {
  const { smallTablet } = useBreakpoints();

  const SLICE_BACKGROUND_COLOR_HEX = "#EFEFEF";

  if (!color.hex) return null;

  const rgb = hexToRGB(color.hex);
  const { blue, green, red } = rgb;

  const getTextColor = () => {
    // Calculate the brightness using the formula: (R * 299 + G * 587 + B * 114) / 1000
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    return brightness > 128 ? "black" : "white";
  };
  const textColor = getTextColor();

  const borderColor =
    color.hex === SLICE_BACKGROUND_COLOR_HEX ? "#DEDEDE" : color.hex;

  return (
    <>
      {smallTablet ? (
        <DesktopColorTile
          borderColor={borderColor}
          hex={color.hex}
          rgb={rgb}
          textColor={textColor}
          title={color.title || ""}
        />
      ) : (
        <MobileColorTile
          borderColor={borderColor}
          hex={color.hex}
          rgb={rgb}
          textColor={textColor}
          title={color.title || ""}
        />
      )}
    </>
  );
};

export default ColorTile;
