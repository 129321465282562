// extracted by mini-css-extract-plugin
export var accentColor = "styles-module--accentColor--a220d";
export var colorValues = "styles-module--colorValues--a9bfc";
export var container = "styles-module--container--a4f8e";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--b2426";