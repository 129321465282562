import React from "react";
import cn from "classnames";
import { Grid, SliceConfig, IntersectionAnimation, Button } from "~components";
import { ITypeExample } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: ITypeExample;
}

const TypeExample = ({
  data: {
    font,
    content,
    contentColumn2,
    sliceConfig,
    fontSize,
    exampleTextColor,
    letterSpacing,
    lineHeight,
    isButton
  }
}: IProps) => {
  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <Grid noPadding>
            <div className={styles.properties}>
              <p className="b1">Example</p>

              <div className={cn(["b2", styles.propertyValues])}>
                <p>{font}</p>
                <p>{fontSize}</p>
                <p>{lineHeight} line height</p>
                <p>{letterSpacing} letter spacing</p>
              </div>
            </div>

            <div
              className={styles.content}
              style={{ color: exampleTextColor?.hex }}
            >
              {!isButton && (
                <>
                  <p style={{ fontSize, lineHeight, fontFamily: font }}>
                    {content}
                  </p>

                  {contentColumn2 && (
                    <p style={{ fontSize, lineHeight, fontFamily: font }}>
                      {contentColumn2}
                    </p>
                  )}
                </>
              )}

              {isButton && (
                <div>
                  <Button iconRight="arrowRight">{content}</Button>
                </div>
              )}
            </div>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default TypeExample;
