import React from "react";
import cn from "classnames";
import { SliceConfig, Grid } from "~components";
import PageNavButton from "./components/PageNavButton";
import { useApp, usePageNavigation } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  colorStyles?: IColorStyles;
  theme?: string;
}

interface IColorStyles {
  backgroundColor?: string;
  color?: string;
}

const PageNavButtons = ({ colorStyles, theme }: IProps) => {
  const { pathname } = useApp();

  const { getPrevPage, getNextPage } = usePageNavigation();

  const prevPage = getPrevPage(pathname);
  const nextPage = getNextPage(pathname);

  const lightTheme = theme?.toLowerCase() === "light";

  return (
    <nav id="nav" style={colorStyles} className={styles.container}>
      <SliceConfig>
        <Grid noPadding>
          {prevPage && (
            <PageNavButton
              direction="prev"
              className={cn([
                styles.prevButton,
                { [styles.light]: lightTheme }
              ])}
              to={`/${prevPage.slug.current}`}
              text={`${prevPage?.prefix} ${prevPage.title}`}
            />
          )}
          {nextPage && (
            <PageNavButton
              direction="next"
              className={cn([
                styles.nextButton,
                { [styles.light]: lightTheme }
              ])}
              to={`/${nextPage.slug.current}`}
              text={`${nextPage?.prefix} ${nextPage.title}`}
            />
          )}
        </Grid>
      </SliceConfig>
    </nav>
  );
};

export default PageNavButtons;
