import React from "react";
import cn from "classnames";
import { SectionConfig, SliceConfig, IntersectionAnimation } from "~components";
import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { IImageGrid } from "~schemas";

interface IProps {
  data: IImageGrid;
}

const ImageGrid = ({
  data: { heading, images, columns, sliceConfig }
}: IProps) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <SectionConfig noPadding>
      <SliceConfig config={{ slicePadding: sliceConfig?.slicePadding }}>
        {heading && (
          <IntersectionAnimation className={styles.headingContainer}>
            <h3 className={cn(["b1", styles.heading])}>{heading}</h3>
          </IntersectionAnimation>
        )}

        <IntersectionAnimation className={styles.intersectionAnimation}>
          <SliceConfig
            config={{
              backgroundColor: sliceConfig?.backgroundColor,
              sliceBorderRadius: sliceConfig?.sliceBorderRadius,
              slicePadding: {
                paddingBottom: "none",
                paddingTop: "none"
              }
            }}
          >
            <div
              className={styles.imageGrid}
              style={{ gridTemplateColumns: `repeat(${columns || 3}, 1fr)` }}
            >
              {images.map((image, i) => (
                <IntersectionAnimation
                  animation="fadeGrow"
                  delay={i * 100}
                  key={i}
                  className={styles.imageContainer}
                >
                  <GatsbyImage alt="" image={image.asset.gatsbyImageData} />
                </IntersectionAnimation>
              ))}
            </div>
          </SliceConfig>
        </IntersectionAnimation>
      </SliceConfig>
    </SectionConfig>
  );
};

export default ImageGrid;
