import React from "react";
import * as styles from "./styles.module.scss";
import { IAllColors, IColor } from "~schemas";
import { SliceConfig } from "~components";
import { graphql, useStaticQuery } from "gatsby";
import ColorTile from "./components/ColorTile";

interface IProps {
  data: IAllColors;
}

const AllColors = ({ data: { sliceConfig } }: IProps) => {
  const colors = useStaticQuery(query).allSanityColor.nodes as IColor[];

  const coreColors = colors.filter((color) => color.category === "core");
  const accentColors = colors.filter((color) => color.category === "accent");
  const tertiaryColors = colors.filter(
    (color) => color.category === "tertiary"
  );

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <div className={styles.colorCategory}>
          {coreColors.map((color, i) => (
            <ColorTile color={color} key={i} />
          ))}
        </div>

        <div className={styles.colorCategory}>
          {accentColors.map((color, i) => (
            <ColorTile color={color} key={i} />
          ))}
        </div>

        <div className={styles.colorCategory}>
          {tertiaryColors.map((color, i) => (
            <ColorTile color={color} key={i} />
          ))}
        </div>
      </div>
    </SliceConfig>
  );
};

export default AllColors;

const query = graphql`
  query AllColorsQuery {
    allSanityColor(sort: { orderRank: ASC }) {
      nodes {
        title
        id
        hex
        category
      }
    }
  }
`;
