import React from "react";
import { Grid, IntersectionAnimation, SVG } from "~components";
import * as styles from "./styles.module.scss";
import { IVideoAtf } from "~schemas";

interface IProps {
  data: IVideoAtf;
}

const FeaturedText = ({ data: { url, heading } }: IProps) => (
  <section className={styles.container}>
    {heading && (
      <div className={styles.content}>
        <Grid className={styles.grid}>
          <IntersectionAnimation className={styles.text}>
            <p className="label">{heading}</p>
          </IntersectionAnimation>

          <figure className={styles.wordmarkContainer}>
            <SVG svg="wordmark" />
          </figure>
        </Grid>
      </div>
    )}

    <video playsInline muted loop autoPlay src={url} />
  </section>
);

export default FeaturedText;
