import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useApp } from "~hooks";
import {
  NoJs,
  Theme,
  Header,
  PageNavButtons,
  Footer,
  Notification
} from "~components";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

interface IColorStyles {
  backgroundColor?: string;
  color?: string;
}

const Layout = ({ children, className }: IProps) => {
  const { theme } = useApp();

  const [colorStyles, setColorStyles] = useState<IColorStyles>({});

  useEffect(() => {
    if (!theme) return;

    setColorStyles({
      backgroundColor: theme === "dark" ? `#000000` : `#EFEFEF`,
      color: theme === "dark" ? `var(--color-off-white)` : `var(--color-black)`
    });
  }, [theme]);

  return (
    <>
      <NoJs />
      <Theme />
      <div style={colorStyles} className={cn(styles.container, className)}>
        {children}
      </div>
      <Header />
      <PageNavButtons colorStyles={colorStyles} theme={theme} />
      <Footer theme={theme} />
      <Notification />
    </>
  );
};

export default Layout;
